.calculator {
  height: 50vh;
  width: 50vw;
}
.GCalc .modal-content {
  height: fit-content;
  width: fit-content !important;
}

.CloseButton {
  cursor: pointer;
}
