.modal80w {
  /* height: fit-content; */
  width: 80%;
}
@media (min-width: 576px) /* The minimum width of the display area,
  such as a browser window*/ {
  .modal-dialog {
    max-width: 1000px;
  }
}
