.modal-backdrop.ReportBackdrop {
  background-color: transparent;
}
.modal-content.ReportContent {
  box-shadow: 0px 2px 13px 4px #888888;
  width: 400px;
  position: relative;
  top: 50px;
  right: 200px;
}
.modal-header {
  padding: 0.8rem 0.8rem;
}

.modal-dialog-centered {
  justify-content: center !important;
}
.modal-content {
  width: 100vw !important;
}
